import axios from 'axios';

//Set defaults
axios.defaults.maxRedirects = 5;
axios.defaults.headers = {
    "Authorization": "Bearer 661fd6fc-b4cc-4ee0-bcb0-babb174f5da1",
    "Accept": "application/json",
};

// Axios headers in case defaults need to be overwritten
let config = {
        timeout: 30000
};

//functions available after import of this file
/**
 * Example import: import recorded from "<PATH>/Recorded.js"
 * Example uses:
 * ASYNC FUNCTION: let response = await recorded.get("/samples", { with:'tests' });
 * or
 * REGULAR FUNCTION: recorded.get("/samples", { with:'tests' }).then(response => { console.log(response) });
 *
 */

export default {

    get(route, data = {}, headers = null){
        headers = (headers) ? headers : config.headers;
        // return axios.get('http://localhost/api' + route, {
        return axios.get('https://wa.ccrs.conflabs-apis.com/api' + route, {
            headers,
            params: data
        }).then(response => {
            return response;
        })
    },
    post(route, data = {}, headers = null){
        headers = (headers) ? headers : config.headers;
        // return axios.post('http://localhost/api' + route, data, headers).then(response => {
        return axios.post('https://wa.ccrs.conflabs-apis.com/api' + route, data, headers).then(response => {
            return response;
        })
    },

    download(route, data = {}, responseType = 'arraybuffer', headers = null) {
        headers = (headers) ? headers :  {
            'Content-Type': 'application/json',
            'Accept': 'text/csv'
        }
        // return axios.get('http://localhost/api' + route, {
            return axios.get('https://wa.ccrs.conflabs-apis.com/api' + route, {
            responseType,
            headers,
            params: data
        })
    },
}